.navbar {
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border:none;
    margin-bottom: -10px; /* Add this line to remove the white line */
}

.logo {
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.nav-links li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.contact-button {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
 font-family: 'Open Sans', sans-serif;;
}
