.cube {
  width: 100px;
  height: 100px;
  transform-style: preserve-3d;
  animation: animate 4s linear infinite;
  cursor: pointer;
  transition: 0.5s;
}

.cube__face {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.faceBox {
  border: calc(100px / 1000) solid rgba(0, 0, 0, 0.8);
  width: calc(100px / 3);
  height: calc(100px / 3);
  opacity: 1;
}

#cube__face--front span {
  background-color: #ff4949;
}

#cube__face--right span {
  background-color: #13ce66;
}

#cube__face--left span {
  background-color: #2d8eff;
}

#cube__face--top span {
  background-color: #ffcc3d;
}

#cube__face--bottom span {
  background-color: #fefefe;
}

#cube__face--back span {
  background-color: #f1f03e;
}

#cube__face--front {
  transform: rotateY(0deg) translateZ(calc(100px / 2));
}

#cube__face--right {
  transform: rotateY(90deg) translateZ(calc(100px / 2));
}

#cube__face--back {
  transform: rotateY(180deg) translateZ(calc(100px / 2));
}

#cube__face--left {
  transform: rotateY(-90deg) translateZ(calc(100px / 2));
}

#cube__face--top {
  transform: rotateX(90deg) translateZ(calc(100px / 2));
}

#cube__face--bottom {
  transform: rotateX(-90deg) translateZ(calc(100px / 2));
}

@keyframes animate {
  0% {
    transform: rotateX(-30deg) rotateY(0deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(-30deg) rotateY(360deg) rotateZ(360deg);
  }
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.construction-text {
  color: white;
  margin-top: 20px;
  font-size: 18px;
}
