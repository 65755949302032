.main-page {
    background: black;
    height: calc(100vh - 140px); /* Adjust the height to leave space for the footer */
    color: white;
    overflow: auto;
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
}


